<template>
  <div class="post-it" :class="$store.state.mobileView ? 'mobile' : 'desktop'">
    <div class="reward-modal" v-if="$store.state.showRewardModal">
      <div class="reward-container">
        <div class="title-container">
          You've Earned the<br />
          {{ reward.award }} Award!
        </div>
        <div
          class="reward-images"
          :class="$store.state.roundCounter % 2 === 1 ? 'left' : 'right'"
          :style="{
            backgroundImage:
              'url(' + require('@/assets/rewards/' + reward.athlete) + ')',
          }"
        >
          <img
            class="reward-image"
            :src="require('../assets/rewards/' + reward.image)"
          />
        </div>
        <button v-on:click="showOutcome" class="reward-button red-buttons">
          Next
        </button>
      </div>
    </div>
    <div class="top-bar">
      <Hud />
    </div>
    <div
      class="bottom-bar"
      :class="$store.state.showRoundFeedback ? 'end-feedback' : ''"
    >
      <div class="phone">
        <div class="phone-top" v-if="!$store.state.mobileView">
          <div class="speaker"></div>
          <div class="camera"></div>
        </div>
        <div class="phone-bottom" v-if="!$store.state.showRoundFeedback">
          <div class="post">
            <div
              v-if="postData.image"
              class="post-image"
              :style="{
                'background-image':
                  'url(' + require('../assets/round4/' + postData.image) + ')',
              }"
            ></div>
            <div class="post-text" :class="{ noImage: !postData.image }">
              <input
                class="audio"
                type="image"
                v-on:click="$store.dispatch('playVO', postData.id)"
                :src="require('../assets/play.png')"
                alt="play sound"
              />
              {{ postData.text }}
            </div>
          </div>
          <div class="below-post">
            <div v-if="!$store.state.showFeedback" class="buttons">
              <input
                type="image"
                class="dont post-button"
                v-on:click="buttonClicked(false)"
                :src="require('../assets/btn_x.png')"
              />
              <input
                type="image"
                class="maybe post-button"
                v-on:click="buttonClicked(null)"
                :src="require('../assets/btn_maybe.png')"
              />
              <input
                type="image"
                class="send post-button"
                v-on:click="buttonClicked(true)"
                :src="require('../assets/btn_send.png')"
              />
            </div>
            <div v-else>
              <div class="feedback-text">
                <input
                  class="audio"
                  type="image"
                  v-on:click="$store.dispatch('playVO', postData.audio)"
                  :src="require('../assets/play.png')"
                  alt="play sound"
                />
                {{ postData.feedback }}
              </div>
              <button class="yellow-buttons" v-on:click="nextQuestion">
                {{ next }}
              </button>
            </div>
          </div>
        </div>
        <div v-else class="conclusion-feedback">
          <div class="advice-feedback">
            <img src="../assets/trafficlight.png" />
            <input
              class="audio"
              type="image"
              v-on:click="$store.dispatch('playVO', advice.id)"
              :src="require('../assets/play.png')"
              alt="play sound"
            />
            <div class="advice-text">{{ advice.text }}</div>
          </div>
          <div class="round-feedback">
            <div class="feedback-image"></div>
            <div class="round-feedback-right">
              <div class="round-feedback-text">
                <div>Nice Job! You scored</div>
                <div>{{ $store.state.roundScore + " points" }}</div>
                <div>Keep hustling!</div>
              </div>
              <button class="red-buttons" v-on:click="showReward">
                {{ $store.state.nextButtonText }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Hud from "@/components/Hud.vue";
export default {
  name: "PostIt",
  components: {
    Hud,
  },
  data() {
    return {
      advice: "",
      next: "Next Question",
    };
  },
  mounted() {
    this.advice = this.$store.state.content.round4.info["4-conclusion"];
    this.$store.commit("getFocusableItems");
    // autoplay first audio file
    if (this.$store.state.audio) {
      this.playAudioWithInstructions(
        this.$store.state.roundCounter +
          "-" +
          (this.$store.state.showQuestion ? "question" : "setup") +
          "-" +
          this.$store.state.decisionCounter +
          (this.$store.state.showQuestion
            ? ""
            : "-" + this.$store.state.setupCounter)
      );
    }
  },
  methods: {
    playAudioWithInstructions(name) {
      this.$store.commit("stopAllSounds");
      let that = this;
      clearTimeout(this.$store.state.timeout);
      if (this.$store.state.audio) {
        this.$store.state.loadedSounds["should-you-post"].play();
        this.$store.state.loadedSounds["should-you-post"].once("end", () => {
          this.$store.state.timeout = setTimeout(function () {
            that.$store.state.loadedSounds[name].play();
          }, 500);
        });
      }
    },
    buttonClicked(answer) {
      this.$store.commit("stopAllSounds");
      if (answer === this.postData.post) {
        this.$store.commit("updateScore", 10);
      }
      this.$store.state.showFeedback = true;
      clearTimeout(this.$store.state.timeout);
      // autoplay first audio file
      if (this.$store.state.audio) {
        this.$store.dispatch(
          "playVO",
          this.$store.state.roundCounter +
            "-feedback-" +
            this.$store.state.decisionCounter +
            "-" +
            this.$store.state.setupCounter
        );
      }
    },
    nextQuestion() {
      this.$store.commit("stopAllSounds");
      if (
        this.$store.state.decisionCounter ===
        Object.keys(
          this.$store.state.content["round" + this.$store.state.roundCounter]
        ).length -
          1
      ) {
        // check for end of rounds
        if (
          this.$store.state.roundCounter <
          Object.keys(this.$store.state.content).length
        ) {
          this.$store.state.roundCounter += 1;
          this.$store.state.decisionCounter = 1;
          this.$store.state.setupCounter = 1;
          window.dispatchEvent(new Event("resize"));
          if (
            this.$store.state.currentRoute === "Dialogue" &&
            this.$store.state.content["round" + this.$store.state.roundCounter]
              .info.title.interaction === "in person"
          ) {
            this.$store.state.mode = "inperson";
          } else if (
            this.$store.state.currentRoute === "Dialogue" &&
            this.$store.state.content["round" + this.$store.state.roundCounter]
              .info.title.interaction === "text message"
          ) {
            this.$store.state.mode = "textmessage";
          } else {
            this.$store.state.mode = "postit";
          }
          this.$store.state.showFeedback = false;
          this.$store.state.setupCounter = 1;
        } else {
          this.$store.state.showRoundFeedback = true;
          if (this.$store.state.audio) {
            clearTimeout(this.$store.state.timeout);
            this.$store.dispatch("playVO", this.advice.id);
            // this.$store.dispatch('playVO', this.$store.state.roundCounter + '-' + (this.$store.state.showQuestion ? 'question' : 'setup') + '-' +this.$store.state.decisionCounter + (this.$store.state.showQuestion ? '' : ('-' + this.$store.state.setupCounter)));
          }
          this.$store.commit("setProgress", 100);
          this.$store.state.nextButtonText = "Next";
        }
      } else {
        this.$store.state.showFeedback = false;
        this.$store.state.setupCounter = 1;
        this.$store.state.decisionCounter += 1;
      }
      // autoplay next audio
      if (this.$store.state.audio && !this.$store.state.showRoundFeedback) {
        // this.$store.dispatch(
        //   "playVO",
        //   this.$store.state.roundCounter +
        //     "-" +
        //     (this.$store.state.showQuestion ? "question" : "setup") +
        //     "-" +
        //     this.$store.state.decisionCounter +
        //     (this.$store.state.showQuestion
        //       ? ""
        //       : "-" + this.$store.state.setupCounter)
        // );
        this.playAudioWithInstructions(
          this.$store.state.roundCounter +
            "-" +
            (this.$store.state.showQuestion ? "question" : "setup") +
            "-" +
            this.$store.state.decisionCounter +
            (this.$store.state.showQuestion
              ? ""
              : "-" + this.$store.state.setupCounter)
        );
      }
      if (!this.$store.state.showRoundFeedback)
        this.$store.commit(
          "setProgress",
          ((this.$store.state.decisionCounter - 1) /
            (Object.keys(
              this.$store.state.content[
                "round" + this.$store.state.roundCounter
              ]
            ).length -
              1)) *
            100
        );
    },
    showReward() {
      this.$store.commit("stopAllSounds");
      this.$store.state.showRewardModal = true;
      if (this.$store.state.audio) {
        clearTimeout(this.$store.state.timeout);
        this.$store.dispatch("playVO", this.reward.audio);
      }
    },
    showOutcome() {
      this.$store.commit("stopAllSounds");
      this.$store.commit("navigate", { route: "Outcome", data: "" });
    },
  },
  computed: {
    postData: function () {
      const roundIndex = "round" + this.$store.state.roundCounter;
      const decisionIndex = "decision" + this.$store.state.decisionCounter;
      const setupIndex =
        this.$store.state.roundCounter +
        "-setup-" +
        this.$store.state.decisionCounter +
        "-" +
        this.$store.state.setupCounter;
      return this.$store.state.content[roundIndex][decisionIndex].setup[
        setupIndex
      ];
    },
    reward: function () {
      let reward = {};
      reward["athlete"] =
        this.$store.state.content["round" + this.$store.state.roundCounter].info
          .title.image + "-athlete.png";
      reward["image"] =
        this.$store.state.content["round" + this.$store.state.roundCounter].info
          .title.image + ".png";
      reward["award"] =
        this.$store.state.rewards[
          this.$store.state.content[
            "round" + this.$store.state.roundCounter
          ].info.title.image
        ];
      reward["audio"] =
        this.$store.state.content["round" + this.$store.state.roundCounter].info
          .title.image + "-award";
      return reward;
    },
  },
  updated() {
    this.$store.commit("getFocusableItems");
  },
};
</script>

<style scoped>
.advice-feedback {
  display: flex;
  align-items: center;
  text-align: left;
  width: 90%;
}

.advice-text{
  font-size: clamp(12px, 2.5vw, 3vh);
}

.advice-feedback > img {
  height: 5em;
}
.advice-feedback > .audio {
  margin: 1.5em;
}

.conclusion-feedback {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  justify-content: space-evenly;
}

.post-it {
  display: grid;
  grid-template-rows: minmax(0, 2fr) minmax(0, 7fr);
  grid-template-columns: minmax(0, 1fr);
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  font-size: clamp(12px, 2.5vw, 3vh);
}

.post-it.mobile {
  font-size: clamp(12px, 3.5vw, 3vh);
}

.top-bar {
  grid-row: 1;
  background-color: var(--top-bar-blue);
}

.bottom-bar {
  display: flex;
  flex-direction: column;
  height: 100%;
  grid-row: 2;
  background-color: var(--off-white);
  justify-content: flex-end;
  align-items: center;
}

.bottom-bar.end-feedback {
  align-content: center;
}

.phone {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 10% 90%;
  border-style: solid;
  border-color: var(--dark-blue);
  border-width: 35px 35px 0px 35px;
  border-radius: 50px 50px 0px 0px;
  box-sizing: border-box;
  height: 97%;
  width: 60vw;
  background-color: white;
}

.mobile > * .phone {
  width: 100%;
  height: 100%;
  grid-template-rows: 1fr;
  border-radius: 0;
  border: none;
}

.phone-top {
  background-color: var(--dark-blue);
  display: flex;
  justify-content: center;
  align-items: center;
}

.camera {
  width: 1.5vh;
  height: 1.5vh;
  margin: 0 0 0 2vh;
  background-color: black;
  border-radius: 50%;
}

.speaker {
  width: 20%;
  height: 15%;
  background-color: black;
  border-radius: 10px;
}

.phone-bottom {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(0, 4fr) minmax(0, 1.5fr);
}

.mobile > * .phone-bottom {
  grid-template-rows: minmax(0, 6fr) minmax(0, 1.5fr);
}

.post {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-variation-settings: "wght" 700;
  background-color: #b4faff;
  padding: 2vh 0;
  box-sizing: border-box;
}

.draft {
  padding: 2vw;
  display: flex;
  width: 70%;
  height: 90%;
  background-color: #6cd4dc;
  border-radius: 25px;
  color: var(--text-blue);
  justify-content: space-evenly;
  box-sizing: border-box;
}

.mobile > * .draft {
  flex-direction: column;
}

.post-image {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

.post-image > img {
  border-radius: 10px;
}

.post-text {
  display: flex;
  align-items: center;
  align-self: center;
  padding: 1%;
}

.post-text.noImage {
  padding: 0 10%;
}

.post-text > .audio {
  margin: 2em;
}

.below-post {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 2%;
}

.feedback-text {
  display: flex;
  align-items: center;
  text-align: left;
  margin-bottom: 1%;
  font-size: clamp(12px, 2vw, 3vh);
}

.mobile > * .feedback-text {
  font-size: clamp(12px, 3vw, 3vh);
}

.buttons {
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.mobile > * .buttons {
  height: 60%;
}

.post-button {
  background-color: rgba(0, 0, 0, 0);
  height: 100%;
  border: none;
  padding: 0;
  max-width: calc(60vw / 3);
}

.round-feedback {
  position: relative;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 2fr minmax(0, 1.5fr);
  border-radius: 20px;
  justify-content: flex-end;
  align-items: center;
  background-color: var(--light-blue);
  width: 80%;
  height: 55%;
  justify-self: center;
  align-self: center;
}

.round-feedback-text {
  display: flex;
  flex-direction: column;
  padding: 2vmin;
  border-radius: 2vmin;
  background-color: var(--yellow);
  font-variation-settings: "wght" 900;
  align-items: center;
  margin: 0.6vmin;
  margin-top: 1vmin;
}

.round-feedback-right {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row: 1;
  align-items: center;
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
}

.feedback-image {
  grid-row: 2;
  grid-column: 1;
  flex-grow: 2;
  background-image: url("../assets/characters/mascot-top.png");
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center;
}
</style>
